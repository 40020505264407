<template>
  <div class="flex flex-col items-center text-center">
    <NuxtLink to="/">
      <div id="logo-title" class="text-3xl font-bold font-light tracking-wide"><span>B</span><span>OGSYNTH</span></div>
    </NuxtLink>
    <!-- Logo Text
      <div id="logo-text" class="text-gray-600 text-sm mt-0">
        Explore a world of web apps
      </div>
      -->
  </div>
</template>
<script setup lang="ts"></script>
